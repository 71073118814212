.attendee-table  {
    border-collapse: collapse !important; /* Collapse the table borders */
    border: 0 !important; /* Remove the table border with !important */
  }
  
.attendee-table td, .attendee-table th {
    border: none; /* Remove borders for table cells and headers */
    padding: 8px; /* Add some padding to the cells for spacing */
  }

